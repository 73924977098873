import moment from 'moment';
import router from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '@/components/common/loading/Loading';
import { GET_ACTIVE_MEMBERSHIP } from '@/graphql/disclosure/queries';
import { GetActiveMembershipQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { AuthorityTypeIdService } from '@/types/authority.type.id.service';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
import WarningIcon from '@mui/icons-material/Warning';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';

type AlertCMRenewalBannerProps = {
  organisationId: string;
};

export const AlertCMRenewalBanner = ({ organisationId }: AlertCMRenewalBannerProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.homepageRequester.renewMembership.alert' });
  const [
    alertOpen,
    setAlertOpen,
  ] = useState(true);
  const { authorityTypeId } = useStore();
  const isCMSAuthorityType = authorityTypeId === AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY();

  const { data, loading } = useQuery<GetActiveMembershipQuery>(GET_ACTIVE_MEMBERSHIP, {
    client: disclosureClient,
    fetchPolicy: 'network-only',
    variables: {
      organisationId,
      authorityTypeId,
    },
    skip: !isCMSAuthorityType,
    onError: onApolloError,
  });

  useEffect(() => {
    setAlertOpen(data?.getActiveMembership.readyForRenewal ?? false);
  }, [
    data?.getActiveMembership.readyForRenewal,
  ]);

  if (!alertOpen || !isCMSAuthorityType) return null;

  const onRenew = () => {
    router.push({
      pathname: Routes.Membership,
    });
  };

  return (
    <Alert
      icon={<WarningIcon />}
      severity="warning"
      onClose={() => setAlertOpen(false)}
      sx={{
        mb: 2,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
      data-testid="membership-renewal-banner"
    >
      {loading ? (
        <Loading isContained />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <AlertTitle data-testid="renewal-banner-title">
              {t('title', {
                daysUntilRenewal: moment(data?.getActiveMembership.endDate).diff(moment(), 'days', false),
              })}
            </AlertTitle>
            <Typography variant="body1">
              {t('description', {
                renewalDate: moment(data?.getActiveMembership.endDate).format('DD/MM/YYYY'),
              })}
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            sx={{
              px: 3,
              ml: 'auto',
            }}
            onClick={onRenew}
            data-testid="renew-button"
          >
            {t('button')}
          </Button>
        </div>
      )}
    </Alert>
  );
};
